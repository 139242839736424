@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
.vertical-slider {

  &_wrapper {
    background: var(--gray-bg);
    border-radius: 12px;
    padding: 120px;
    @include desktopStyle {
      border-radius: get-vw(12px);
      padding: get-vw(120px);
    }
    @media only screen and (max-width: $bp-tablet) {
      padding: 80px 16px;
      margin: 0 -16px;
    }
    @media only screen and (max-width: $bp-phone) {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }
 
  &_content {
    width: 40%;
    max-width: 406px;
    @include desktopStyle {
      max-width: get-vw(406px);
    }
    @media only screen and (max-width: $bp-tablet) {
      width: 100%;
      max-width: none;
      margin-bottom: 32px;
    }
  }

  &_label {
    display: inline-block;
    background: var(--blue);
    padding: 2px 20px;
    color: var(--white);
    margin-bottom: 24px;
    border-radius: 40px;
    @include desktopStyle {
      padding: get-vw(2px) get-vw(20px);
      margin-bottom: get-vw(24px);
      border-radius: get-vw(40px);
    }
    @media only screen and (max-width: $bp-tablet) {
      padding: 0 16px;
      margin-bottom: 16px;
    }
  }

  &_title {
    &:not(:last-child) {
      margin-bottom: 32px;
      @include desktopStyle {
        margin-bottom: get-vw(32px);
      }
      @media only screen and (max-width: $bp-phone) {
        margin-bottom: 24px;
      }
    }
  }

  &_image {
    margin-top: auto;
    @media only screen and (max-width: $bp-tablet) {
      margin-top: unset;
      order: -1;
      margin-bottom: 32px;
      text-align: center;
      width: 100%;
      img {
        max-width: 44.6%;
      }
    }
  }

  &_right {
    width: 50%;
    max-width: 640px;
    @include desktopStyle {
      max-width: get-vw(640px);
    }
    @media only screen and (max-width: $bp-tablet) {
      width: 100%;
      max-width: none;
    }
  }
}