@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
[data-color-scheme="whiteBlack"] {
  .services-hero_link {
    background: #E0E0E0;
    &:hover {
      background: var(--blue);
    }
  }
}

[data-color-scheme="blackWhite"] {
  .services-hero_link {
    &:hover {
      .services-hero_link-icon {
        filter: grayscale(100%);
      }
    }
  }
}
[data-color-scheme="blue"] {
  .services-hero_link:not(:hover) {
    background: var(--white);
  }
}


.services-hero {
  background: var(--blue-light);
  padding-bottom: 120px;
  @include desktopStyle {
    padding-bottom: get-vw(120px);
  }
  @media only screen and (max-width: $bp-tablet) {
    padding-bottom: 80px;
    :global(.breadcrumbs) {
      padding-top: 0;
      padding-bottom: 16px;
    }
  }
  @media only screen and (max-width: $bp-phone) {
    padding-bottom: 40px;
  }

  &_content {
    margin-top: 80px;
    gap: 60px;
    grid-gap: 60px;
    @include desktopStyle {
      margin-top: get-vw(80px);
      gap: get-vw(60px);
      grid-gap: get-vw(60px);
    }
    @media only screen and (max-width: $bp-tablet) {
      margin-top: 60px;
      gap: 32px;
      grid-gap: 32px;
    }
    @media only screen and (max-width: $bp-phone) {
      margin-top: 40px;
      gap: 12px;
      grid-gap: 12px;
    }
  }

  &_link {
    text-decoration: none;
    width: calc(50% - 30px);
    background: var(--bg-blue-light-3);
    border-radius: 12px;
    padding: 40px 140px 40px 40px;
    line-height: 1.3;
    color: var(--gray-dark);
    position: relative;
    transition: all 0.3s;
    @include desktopStyle {
      width: calc(50% - get-vw(30px));
      border-radius: get-vw(12px);
      padding: get-vw(40px) get-vw(140px) get-vw(40px) get-vw(40px);
    }
    @media only screen and (max-width: $bp-tablet) {
      width: calc(50% - 16px);
      padding: 32px 110px 32px 32px;
      font-size: 2.4rem;
    }
    @media only screen and (max-width: $bp-phone) {
      padding: 24px 80px 24px 24px;
      width: 100%;
      border-radius: 8px;
      font-size: 1.6rem;
      line-height: 1.5;
    }
    &:hover {
      color: var(--white);
      background: var(--blue);
      .services-hero_link-icon {
        animation: scaleIcon 0.6s ease-in-out;
        filter: brightness(0) invert(1);
      }
      @media only screen and (max-width: $bp-phone) {
        :global(.bg-white) {
          color: var(--white);
        }
      }
    }
  }

  &_link-icon {
    width: 96px;
    height: 96px;
    @include desktopStyle {
      width: get-vw(96px);
      height: get-vw(96px);
    }
    @media only screen and (max-width: $bp-tablet) {
      width: 72px;
      height: 72px;
    }
    @media only screen and (max-width: $bp-phone) {
      width: 48px;
      height: 48px;
    }
    img {
      display: block;
    }
  }

  &_link-name {
    margin-top: auto;
    padding-top: 82px;
    display: block;
    @include desktopStyle {
      padding-top: get-vw(82px);
    }
    @media only screen and (max-width: $bp-tablet) {
      padding-top: 48px;
    }
    @media only screen and (max-width: $bp-phone) {
      padding-top: 24px;
    }
  }

  &_link-arrow {
    position: absolute;
    display: block;
    right: 40px;
    bottom: 40px;
    @include desktopStyle {
      right: get-vw(40px);
      bottom: get-vw(40px);
    }
    @media only screen and (max-width: $bp-tablet) {
      right: 32px;
      bottom: 32px;
    }
    @media only screen and (max-width: $bp-phone) {
      background: transparent;
      right: 24px;
      bottom: 24px;
    }
    &:global(.bg-white) {
      &:hover {
        color: var(--gray-dark);
        border-color: var(--white);
        background-color: var(--white);
      }
      @media only screen and (max-width: $bp-phone) {
        background: transparent;
        border: none;
        &:hover {
          background: transparent;
          color: var(--white);
        }
      }
    }
    &:global(.b-only-icon) {
      width: 48px;
      height: 48px;
      @include desktopStyle {
        width: get-vw(48px);
        height: get-vw(48px);
        svg {
          width: get-vw(16px);
          height: get-vw(16px);
        }
      }
      @media only screen and (max-width: $bp-phone) {
        width: 16px;
        height: 16px;
      }
    }
  }
}

@keyframes scaleIcon {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}