@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
.teams-slider {
  padding: 120px 0;
  @include desktopStyle {
    padding-top: get-vw(120px);
    padding-bottom: get-vw(120px);
  }
  @media only screen and (max-width: $bp-tablet) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  @media only screen and (max-width: $bp-phone) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &_wrapper {
    :global(.slick-arrow) {
      padding: 0;
      background: transparent;
      cursor: pointer;
      outline: none;
      font-size: 0;
      position: absolute;
      z-index: 2;
      bottom: 0;
      width: 24px;
      height: 24px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      border: none;
      transition: all 0.3s;
      color: var(--gray-dark);
      transform: translateX(100%);
      @include desktopStyle {
        width: get-vw(24px);
        height: get-vw(24px);
      }
      &:hover {
        filter: invert(51%) sepia(93%) saturate(284%) hue-rotate(163deg) brightness(89%) contrast(105%);
      }
    }
    :global(.slick-disabled) {
      opacity: 0.2;
      &:hover {
        filter: none;
      }
    }
    :global(.slick-prev) {
      right: 47.5%;
      background-image: url('~~/app/assets/images/icons/arrow-left-v2.svg');
      @media only screen and (max-width: $bp-tablet) {
        right: 100%;
      }
    }
    :global(.slick-next) {
      right: 47.5%;
      margin-right: -48px;
      background-image: url('~~/app/assets/images/icons/arrow-right-v2.svg');
      @include desktopStyle {
        margin-right: get-vw(-48px);
      }
      @media only screen and (max-width: $bp-tablet) {
        right: 100%;
      }
    }
  }

  &_block {
    display: flex !important;
    @media only screen and (max-width: $bp-tablet) {
      justify-content: center;
    }
  }

  &_image {
    width: 47.5%;
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    @media only screen and (max-width: $bp-tablet) {
      width: 100%;
      max-width: 768px;
      margin-bottom: 40px;
      border-radius: 8px;
    }
    img {
      display: block;
      width: 100%;
    }
  }

  &_content {
    width: 47.5%;
    padding-bottom: 64px;
    @include desktopStyle {
      padding-bottom: get-vw(64px);
    }
    @media only screen and (max-width: $bp-tablet) {
      width: 100%;
      max-width: 768px;
      padding-bottom: 56px;
    }
  }

  &_quotation,
  &_user-name {
    line-height: 1.3; 
  }

  &_text {
    margin-top: 32px;
    max-width: 664px;
    @include desktopStyle {
      margin-top: 32px;
      max-width: get-vw(664px);
    }
    @media only screen and (max-width: $bp-tablet) {
      margin-top: 24px;
      max-width: none;
    }
    @media only screen and (max-width: $bp-phone) {
      margin-top: 16px;
    }
  }

  &_quotation-icon {
    color: #EFF1F2;
    margin-bottom: 32px;
    width: 60px;
    height: 53px;
    @include desktopStyle {
      width: get-vw(60px);
      height: get-vw(53px);
      margin-bottom: get-vw(32px);
    }
    @media only screen and (max-width: $bp-tablet) {
      width: 44px;
      height: 38px;
      margin-bottom: 24px;
    }
    @media only screen and (max-width: $bp-phone) {
      margin-bottom: 16px;
    }
    svg {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
  }

  &_bottom {
    margin-top: auto;
    padding-top: 40px;
    @include desktopStyle {
      padding-top: get-vw(40px);
    }
  }

  &_user-position {
    margin-top: 12px;
    color: var(--gray);
    @include desktopStyle {
      margin-top: get-vw(12px);
    }
  }
}
