@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
[data-color-scheme="blackWhite"] {
  .service-content_block-logo {
    img {
      filter: brightness(0) invert(1);
    }
  }
}

.service-content {
  background: var(--blue-light);
  padding-bottom: 120px;
  @include desktopStyle {
    padding-bottom: get-vw(120px);
  }
  @media only screen and (max-width: $bp-tablet) {
    padding-bottom: 80px;
  }
  @media only screen and (max-width: $bp-phone) {
    padding-bottom: 40px;
  }
  :global(.breadcrumbs) {
    padding-bottom: 40px;
    @include desktopStyle {
      padding-bottom: get-vw(40px);
    }
    @media only screen and (max-width: $bp-tablet) {
      padding-top: 0;
      padding-bottom: 32px;
    }
  }

  &_wrapper {
    @media only screen and (max-width: $bp-tablet) {
      display: block;
    }
  }

  &_title {
    color: var(--gray-dark);
    margin-bottom: 16px;
    max-width: 1056px;
    @include desktopStyle {
      max-width: get-vw(1056px);
      margin-bottom: get-vw(16px);
    }
    @media only screen and (max-width: $bp-tablet) {
      margin-bottom: 24px;
    }
  }

  &_sidebar {
    flex: 0 1 413px;
    padding-right: 32px;
    padding-top: 20px;
    @include desktopStyle {
      flex-basis: get-vw(413px);
      padding-right: get-vw(32px);
      padding-top: get-vw(20px);
    }
    @media only screen and (max-width: $bp-tablet) {
      display: none;
    }

    &-item {
      &:not(:last-child) {
        margin-bottom: 24px;
        @include desktopStyle {
          margin-bottom: get-vw(24px);
        }
      }
    }

    &-btn {
      background: none;
      border: none;
      padding: 0;
      cursor: pointer;
      transition: color .3s;
      text-align: left;
      line-height: 1.5;
      color: var(--gray);
      outline: none;
      svg {
        display: block;
        margin-left: 16px;
        width: 20px;
        height: 20px;
        opacity: 0;
        transform: translateX(-5px);
        transition: opacity .3s, transform .3s;
        @include desktopStyle {
          margin-left: get-vw(16px);
          width: get-vw(20px);
          height: get-vw(20px);
        }
      }

      &.b-active,
      &:hover {
        color: var(--blue);
        svg {
          opacity: 1;
          transform: none;
        }
      }
    }
  }

  &_content {
    flex: 1;
  }

  &_payments {
    margin-top: 60px;
    gap: 40px;
    grid-gap: 40px;
    @include desktopStyle {
      margin-top: get-vw(60px);
      gap: get-vw(40px);
      grid-gap: get-vw(40px);
    }
    @media only screen and (max-width: $bp-tablet) {
      margin-top: 24px;
      gap: 24px;
      grid-gap: 24px;
    }
  }

  &_block {
    background: var(--white);
    width: calc(50% - 20px);
    border-radius: 12px;
    padding: 40px;
    height: 309px;
    @include desktopStyle {
      width: calc(50% - get-vw(20px));
      border-radius: get-vw(12px);
      padding: get-vw(40px);
      height: get-vw(309px);
    }
    @media only screen and (max-width: $bp-tablet) {
      width: calc(50% - 12px);
      padding: 32px;
      height: 240px;
    }
    @media only screen and (max-width: $bp-phone) {
      padding: 24px;
      height: 180px;
      width: 100%;
    }
  }

  &_block-logo {
    height: 100px;
    margin-bottom: 30px;
    @include desktopStyle {
      height: get-vw(100px);
    }
    @media only screen and (max-width: $bp-tablet) {
      height: 80px;
    }
    @media only screen and (max-width: $bp-phone) {
      height: 60px;
    }
    img {
      display: block;
      max-height: 100%;
    }
  }

  &_block-link {
    margin-top: auto;
    font-size: 1.6rem;
    border-radius: 90px;
    padding: 11px 18px;
    @include desktopStyle {
      border-radius: get-vw(90px);
      padding: get-vw(11px) get-vw(18px);
      svg {
        width: get-vw(12px);
        height: get-vw(13px);
      }
    }
    @media only screen and (max-width: $bp-phone) {
      font-size: 1.5rem;
      padding: 10px 17px;
    }
    svg {
      display: block;
    }
  }
}
