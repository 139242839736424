@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
[data-color-scheme="whiteBlack"],
[data-color-scheme="blue"] {
  .useful-links_slider-link {
    background: transparent;
    border: 1px solid var(--gray-dark);
    &:not(:hover) {
      .useful-links_slider-icon {
        filter: brightness(0%);
      }
    }
    &:hover {
      background: var(--gray-dark) !important;
    }
  }
}

[data-color-scheme="blackWhite"] {
  .useful-links_slider-link {
    background: transparent;
    border: 1px solid var(--gray-dark);
    .useful-links_slider-icon {
      filter: brightness(0) invert(1);
    }
    &:hover {
      background: var(--gray-dark) !important;
      .useful-links_slider-icon {
        filter: grayscale(100%);
      }
    }
  }
}

.useful-links {
  padding: 80px 0;
  @include desktopStyle {
    padding-top: get-vw(80px);
    padding-bottom: get-vw(80px);
  }
  @media only screen and (max-width: $bp-tablet) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &_title {
    padding-right: 100px;
    @include desktopStyle {
      padding-right: get-vw(100px);
    }
    @media only screen and (max-width: $bp-phone) {
      padding-right: 0;
    }
  }

  &_slider {
    padding-top: 60px;
    position: relative;
    @include desktopStyle {
      padding-top: get-vw(60px);
    }
    @media only screen and (max-width: $bp-tablet) {
      padding-top: 48px;
    }
    @media only screen and (max-width: $bp-phone) {
      padding-top: 32px;
    }
    :global(.slick-list) {
      margin: 0 -8px;
    }
    :global(.slick-track) {
      display: flex;
      align-items: stretch;
      height: auto;
    }
    :global(.slick-slide) {
      height: auto;

      & > div {
        height: 100%;
      }
    }
    :global(.slick-arrow) {
      padding: 0;
      background: transparent;
      cursor: pointer;
      outline: none;
      font-size: 0;
      position: absolute;
      z-index: 2;
      top: -20px;
      width: 32px;
      height: 20px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      border: none;
      transition: all 0.3s;
      @include desktopStyle {
        top: get-vw(-20px);
        width: get-vw(32px);
        height: get-vw(20px);
      }
      &:not(.slick-disabled):hover {
        filter: invert(51%) sepia(93%) saturate(284%) hue-rotate(163deg) brightness(89%) contrast(105%);
      }
    }
    :global(.slick-disabled) {
      opacity: 0.6;
    }
    :global(.slick-next) {
      right: 0;
      background-image: url("~~/app/assets/images/icons/arrow-right.svg");
    }
    :global(.slick-prev) {
      right: 40px;
      background-image: url("~~/app/assets/images/icons/arrow-left.svg");
      @include desktopStyle {
        right: get-vw(40px);
      }
    }
  }

  &_slider-block {
    height: 100%;
    padding: 0 8px;
    @media only screen and (max-width: $bp-phone) {
      padding: 0;
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &_slider-link {
    height: 100%;
    text-decoration: none;
    font-family: $font-main-SB;
    font-size: 2.4rem;
    line-height: 1.5;
    color: var(--gray-dark);
    background: var(--gray-bg-2);
    border-radius: 12px;
    padding: 31px 40px;
    transition: all 0.3s;
    @include desktopStyle {
      padding: get-vw(40px) get-vw(40px);
    }
    @media only screen and (max-width: $bp-phone) {
      font-size: 1.6rem;
      padding: 16px;
    }
    $colors:
      "blue" var(--blue),
      "blue_light" var(--bg-blue-light),
      "pink_dark" var(--bg-pink-dark),
      "yellow" var(--bg-yellow),
      "yellow_light" var(--bg-yellow-light),
      "green" var(--bg-green);
    @each $name, $bgBlock in $colors {
      &.bg-#{$name} {
        &:hover {
          background: $bgBlock;
        }
      }
    }
    &:hover {
      color: var(--white);
      .useful-links_slider-icon {
        animation: scaleIcon 0.7s ease-in-out;
        filter: brightness(0) invert(1);
      }
    }
  }

  &_slider-icon {
    width: 30px;
    @include desktopStyle {
      width: get-vw(30px);
    }
    @media only screen and (max-width: $bp-phone) {
      width: 24px;
    }
    img {
      display: block;
      width: auto;
      height: auto;
    }
  }

  &_slider-text {
    max-width: calc(100% - 30px);
    padding-left: 20px;
    @include desktopStyle {
      max-width: calc(100% - get-vw(30px));
      padding-left: get-vw(20px);
    }
    @media only screen and (max-width: $bp-phone) {
      max-width: calc(100% - 24px);
      padding-left: 12px;
    }
  }
}

@keyframes scaleIcon {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
