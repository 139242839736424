@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
[data-color-scheme="blue"] {
  .insurance-cases_card {
    background: var(--blue-light) !important;
  }
}

.insurance-cases {
  padding: 80px 0;
  position: relative;
  @include desktopStyle {
    padding: get-vw(80px) 0;
  }
  @media only screen and (max-width: $bp-tablet) {
    padding: 40px 0;
  }

  :global(.tabs_content) {
    margin-top: 0;
  }
}

.insurance-cases_personal {
  margin-top: 48px;
  @include desktopStyle {
    margin-top: get-vw(48px);
  }
  @media only screen and (max-width: $bp-tablet) {
    margin-top: 32px;
  }

  &-cards {
    gap: 40px;
    grid-gap: 40px;
    @include desktopStyle {
      gap: get-vw(40px);
      grid-gap: get-vw(40px);
    }
    @media only screen and (max-width: $bp-tablet) {
      gap: 16px;
      grid-gap: 16px;
    }
    @media only screen and (max-width: $bp-phone) {
      display: block;
    }
  }
}

.insurance-cases_card {
  position: relative;
  width: percent-width-flex-items(4, 40px);
  min-height: 200px;
  padding: 24px 24px 80px;
  border-radius: 8px;
  overflow: hidden;
  color: var(--gray-dark);
  text-decoration: none;
  @include desktopStyle {
    width: percent-width-flex-items(4, get-vw(40px));
    min-height: get-vw(200px);
    padding: get-vw(24px) get-vw(24px) get-vw(80px);
  }
  @media only screen and (max-width: $bp-tablet) {
    width: percent-width-flex-items(2, 16px);
  }
  @media only screen and (max-width: $bp-phone) {
    display: block;
    width: 100%;
    min-height: 155px;
    padding-bottom: 70px;
  }

  &:not(:last-child) {
    @media only screen and (max-width: $bp-phone) {
      margin-bottom: 16px;
    }
  }

  &-image {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 178px;
    height: 138px;
    @include desktopStyle {
      width: get-vw(178px);
      height: get-vw(138px);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: right bottom;
    }
  }
  &-head {
    position: relative;
    max-width: 50%;
    @media only screen and (max-width: $bp-tablet) {
      max-width: 45%;
    }

    &-title {
      line-height: 1.5;
    }
  }
  &-arr {
    position: absolute;
    left: 24px;
    bottom: 16px;
    width: 48px;
    height: 48px;
    color: var(--gray-dark);
    border-radius: 100%;
    transition: background-color .3s;
    @include desktopStyle {
      left: get-vw(24px);
      bottom: get-vw(16px);
      width: get-vw(48px);
      height: get-vw(48px);
    }
    @media only screen and (max-width: $bp-phone) {
      left: 8px;
    }

    &-icon {
      width: 16px;
      height: 16px;
      @include desktopStyle {
        width: get-vw(16px);
        height: get-vw(16px);
      }
    }
  }

  $colors:
    'green' var(--bg-green-2),
    'green_light' var(--bg-green-light-2),
    'green_dark' var(--bg-green),
    'blue' var(--bg-blue-2),
    'blue_light' var(--bg-blue-light),
    'blue_sky' var(--bg-blue-sky),
    'violet_light' var(--bg-violet-light),
    'brown' var(--bg-brown),
    'brown_light' var(--bg-brown-light),
    'pink' var(--bg-pink),
    'pink_light' var(--bg-pink-light),
    'yellow' var(--bg-yellow),
    'turquoise' var(--bg-turquoise);
  @each $name, $bgBlock in $colors {
    &.bg-#{$name} {
      background: $bgBlock;
    }
  }

  &:hover {
    .insurance-cases_card-arr {
      background: var(--white);
      @media only screen and (max-width: $bp-phone) {
        background: none;
      }
    }
  }
}

.insurance-cases_corporate {
  margin-top: 90px;
  @include desktopStyle {
    margin-top: get-vw(90px);
  }
  @media only screen and (max-width: $bp-tablet) {
    display: block;
    margin-top: 32px;
  }

  &-left {
    flex: 0 1 413px;
    padding-right: 60px;
    @include desktopStyle {
      flex-basis: get-vw(413px);
      padding-right: get-vw(60px);
    }
    @media only screen and (max-width: $bp-tablet) {
      padding-right: 0;
      margin-bottom: 40px;
    }

    &-title {
      line-height: 1.3;
      @media only screen and (max-width: $bp-tablet) {
        font-size: 2.4rem;
      }
    }
    &-descr {
      font-size: 2rem;
      font-weight: 500;
      line-height: 1.6;
      margin-top: 24px;
      @include desktopStyle {
        margin-top: get-vw(24px);
      }
      @media only screen and (max-width: $bp-tablet) {
        font-size: 1.5rem;
        margin-top: 16px;
      }
    }
  }

  &-right {
    flex: 1;
  }

  &-hr {
    position: absolute;
    height: 1px;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--gray-second);
  }
}

.insurance-cases_corporate-contacts {
  padding: 60px;
  border-radius: 20px;
  background-color: var(--bg-blue-light-2);
  @include desktopStyle {
    padding: get-vw(60px);
  }
  @media only screen and (max-width: $bp-tablet) {
    padding: 32px;
  }
  @media only screen and (max-width: $bp-phone) {
    padding-right: 20px;
    gap: 40px;
    grid-gap: 40px;
  }

  &:not(:last-child) {
    margin-bottom: 60px;
    @include desktopStyle {
      margin-bottom: get-vw(60px);
    }
    @media only screen and (max-width: $bp-tablet) {
      margin-bottom: 40px;
    }
  }

  & > div {
    width: 50%;
    @media only screen and (max-width: $bp-phone) {
      width: 100%;
    }
  }

  &-title {
    font-size: 1.8rem;
    line-height: 1.5;
    margin-bottom: 16px;
    @include desktopStyle {
      margin-bottom: get-vw(16px);
    }
    @media only screen and (max-width: $bp-tablet) {
      font-size: 1.6rem;
    }
  }

  &-link {
    gap: 8px;
    grid-gap: 8px;
    color: var(--gray-dark);
    text-decoration: none;
    line-height: 1.5;
    @include desktopStyle {
      gap: get-vw(8px);
      grid-gap: get-vw(8px);
    }
    @media only screen and (max-width: $bp-tablet) {
      font-size: 2rem;
      line-height: 1.3;
    }

    &:hover {
      color: var(--blue);
    }
  }

  &-icon {
    width: 24px;
    height: 24px;
    color: var(--blue);
    flex-shrink: 0;
    @include desktopStyle {
      width: get-vw(24px);
      height: get-vw(24px);
    }

    &--gray {
      color: var(--gray-light);
      @media only screen and (max-width: $bp-phone) {
        // width: 16px;
        // height: 16px;
        // color: var(--blue);
      }
    }
  }

  &-phones {
    &-item {
      &:not(:last-child) {
        margin-bottom: 12px;
        @include desktopStyle {
          margin-bottom: get-vw(12px);
        }
      }

      &-tag {
        gap: 8px;
        grid-gap: 8px;
        padding: 2px 10px;
        border-radius: 90px;
        border: 1px solid currentColor;
        color: var(--blue);
        font-family: $font-main-MD;
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1.6;
        @include desktopStyle {
          gap: get-vw(8px);
          grid-gap: get-vw(8px);
          padding: get-vw(2px) get-vw(10px);
        }
        @media only screen and (max-width: $bp-tablet) {
          gap: 4px;
          grid-gap: 4px;
          font-size: 1.3rem;
        }

        img {
          width: 14px;
          height: auto;
          flex-shrink: 0;
          margin-bottom: 1px;
          @include desktopStyle {
            width: get-vw(14px);
            margin-bottom: get-vw(1px);
          }
          @media only screen and (max-width: $bp-tablet) {
            width: 13px;
          }
        }
      }
    }
  }

  &-office {
    @media only screen and (max-width: $bp-phone) {
      order: 1;
    }

    &-item {
      gap: 12px;
      grid-gap: 12px;
      font-family: $font-main-MD;
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 1.6;
      @include desktopStyle {
        gap: get-vw(12px);
        grid-gap: get-vw(12px);
      }
      @media only screen and (max-width: $bp-phone) {
        font-size: 1.5rem;
      }

      &:not(:last-child) {
        margin-bottom: 16px;
        @include desktopStyle {
          margin-bottom: get-vw(16px);
        }
      }
      // &-wr {}
      &-val {
        white-space: pre-line;
      }
      :global(.link-arrow) {
        color: var(--blue);
        gap: 8px;
        grid-gap: 8px;
        font-size: 1.4rem;
        margin-top: 8px;
        @include desktopStyle {
          gap: get-vw(8px);
          grid-gap: get-vw(8px);
          margin-top: get-vw(8px);
        }

        svg {
          width: 12px;
          height: 12px;
          @include desktopStyle {
            width: get-vw(12px);
            height: get-vw(12px);
          }
        }

        &:hover {
          svg {
            transform: translateX(5px);
          }
        }
      }
    }
  }

  &-email {
    &-item {
      &:not(:last-child) {
        margin-bottom: 12px;
        @include desktopStyle {
          margin-bottom: get-vw(12px);
        }
      }
    }
  }
}

.insurance-cases_corporate-info {
  &-item {
    &:not(:last-child) {
      margin-bottom: 60px;
      @include desktopStyle {
        margin-bottom: get-vw(60px);
      }
      @media only screen and (max-width: $bp-tablet) {
        margin-bottom: 40px;
      }
    }

    &-title {
      line-height: 1.3;
      &:not(:last-child) {
        margin-bottom: 40px;
        @include desktopStyle {
          margin-bottom: get-vw(40px);
        }
        @media only screen and (max-width: $bp-tablet) {
          margin-bottom: 32px;
        }
      }
    }

    &-text {
      :global {
        ul {
          margin: 32px 0;
          list-style: none !important;
          @include desktopStyle {
            margin-top: get-vw(32px);
            margin-bottom: get-vw(32px);
          }
          @media only screen and (max-width: $bp-tablet) {
            margin-top: 24px;
            margin-bottom: 24px;
          }
          &:first-child {
            margin-top: 0;
          }
          &:last-child {
            margin-bottom: 0;
          }
          & > li {
            position: relative;
            padding-left: 32px;
            padding-bottom: 20px;
            @include desktopStyle {
              padding-left: get-vw(32px);
              padding-bottom: get-vw(20px);
            }
            &:last-child {
              padding-bottom: 0;
            }
            &::after {
              content: '';
              position: absolute;
              left: 0;
              top: 6px;
              background: url('~~/app/assets/images/icons/minus-circle.svg') center center no-repeat;
              background-size: contain;
              width: 20px;
              height: 20px;
              @include desktopStyle {
                top: get-vw(6px);
                width: get-vw(20px);
                height: get-vw(20px);
              }
              @media only screen and (max-width: $bp-tablet) {
                top: 3px;
              }
            }
          }
        }
      }
    }

    :global {
      .accordion_inner {
        padding-right: 0;
        a {
          color: var(--blue);
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}