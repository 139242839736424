@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
.team-groups {
  padding: 120px 0;
  @include desktopStyle {
    padding-top: get-vw(120px);
    padding-bottom: get-vw(120px);
  }
  @media only screen and (max-width: $bp-tablet) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  @media only screen and (max-width: $bp-phone) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &_wrapper {
    @media only screen and (max-width: $bp-tablet) {
      display: block;
    }
  }

  &_sidebar {
    flex: 0 1 590px;
    padding-right: 32px;
    @include desktopStyle {
      flex-basis: get-vw(590px);
      padding-right: get-vw(32px);
    }
    @media only screen and (max-width: $bp-tablet) {
      padding-right: 0;
      margin-bottom: 32px;
    }

    &-teams {
      margin-top: 32px;
      @include desktopStyle {
        margin-top: get-vw(32px);
      }
      @media only screen and (max-width: $bp-tablet) {
        display: none;
      }

      &-item {
        &:not(:last-child) {
          margin-bottom: 24px;
          @include desktopStyle {
            margin-bottom: get-vw(24px);
          }
        }
      }
    }


    &-btn {
      background: none;
      border: none;
      padding: 0;
      cursor: pointer;
      transition: color .3s;
      text-align: left;
      line-height: 1.5;
      color: var(--gray);
      svg {
        margin-left: 16px;
        width: 20px;
        height: 20px;
        opacity: 0;
        transform: translateX(-5px);
        transition: opacity .3s, transform .3s;
        @include desktopStyle {
          margin-left: get-vw(16px);
          width: get-vw(20px);
          height: get-vw(20px);
        }
      }

      &.b-active,
      &:hover {
        color: var(--blue);
        svg {
          opacity: 1;
          transform: none;
        }
      }
    }
  }

  &_sidebar-title {
    @media only screen and (max-width: $bp-phone) {
      font-size: 2.8rem;
    }
  }

  &_content {
    flex: 1;
  }

  &_content-groups {
    gap: 80px 40px;
    grid-gap: 80px 40px;
    @include desktopStyle {
      gap: get-vw(80px) get-vw(40px);
      grid-gap: get-vw(80px) get-vw(40px);
    }
    @media only screen and (max-width: $bp-tablet) {
      gap: 24px 12px;
      grid-gap: 24px 12px;
    }
    &.b-hide-image {
      gap: 100px 40px;
      grid-gap: 100px 40px;
      @include desktopStyle {
        gap: get-vw(100px) get-vw(40px);
        grid-gap: get-vw(100px) get-vw(40px);
      }
      @media only screen and (max-width: $bp-tablet) {
        gap: 32px 12px;
        grid-gap: 32px 12px;
      }
    }
  }

  &_block {
    width: calc(33.333% - 27px);
    @include desktopStyle {
      width: calc(33.333% - get-vw(27px));
    }
    @media only screen and (max-width: $bp-tablet) {
      width: calc(50% - 6px);
    }
  }

  &_block-image {
    height: 360px;
    margin-bottom: 32px;
    background: var(--bg-blue-light-2);
    overflow: hidden;
    border-radius: 12px;
    @include desktopStyle {
      height: get-vw(360px);
      margin-bottom: get-vw(32px);
      border-radius: get-vw(12px);
    }
    @media only screen and (max-width: $bp-tablet) {
      margin-bottom: 24px;
    }
    @media only screen and (max-width: $bp-phone) {
      border: 8px;
      height: 51.2vw;
      margin-bottom: 16px;
    }
    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }
    & + .team-groups_block-name {
      padding-top: 0;
      &::after {
        content: none;
      }
    }
  }

  &_block-name {
    font-family: $font-main-SB;
    color: var(--gray-dark);
    padding-top: 36px;
    position: relative;
    font-size: 2.4rem;
    line-height: 1.5;
    width: 100%;
    @include desktopStyle {
      padding-top: get-vw(36px);
    }
    @media only screen and (max-width: $bp-tablet) {
      font-size: 2rem;
      padding-top: 24px;
    }
    @media only screen and (max-width: $bp-phone) {
      font-size: 1.6rem;
      padding-top: 19px;
    }
    &::after {
      content: '';
      position: absolute;
      display: block;
      height: 4px;
      left: 0;
      top: 0;
      right: 0;
      background-color: var(--blue);
      border-radius: 2px;
      @media only screen and (max-width: $bp-phone) {
        height: 3px;
      }
    }
  }

  &_block-position {
    margin-top: 12px;
    color: var(--gray);
    @include desktopStyle {
      margin-top: get-vw(12px);
    }
    @media only screen and (max-width: $bp-phone) {
      font-size: 1.4rem;
      margin-top: 8px;
    }
  }

  &_block-btn-more {
    margin-top: auto;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    color: var(--blue);
    font-size: 1.8rem;
    font-family: $font-main-SB;
    padding-top: 12px;
    @include desktopStyle {
      padding-top: get-vw(12px);
    }
    @media only screen and (max-width: $bp-tablet) {
      font-size: 1.6rem;
    }
    @media only screen and (max-width: $bp-phone) {
      padding-top: 8px;
      font-size: 1.4rem;
    }
    &:hover {
      text-decoration: underline;
    }
  }
}
