@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
.slider {
  width: 100%;
  position: relative;
  overflow: hidden;

  &_content {
    max-height: 897px;
    overflow: auto;
    position: relative;
    scroll-behavior: smooth;
    pointer-events: none;
    -ms-overflow-style: none;
    scrollbar-width: none; 
    &::-webkit-scrollbar { 
      display: none;
    }
    // &::-webkit-scrollbar {
    //   width: 6px;
    //   @include desktopStyle {
    //     width: get-vw(6px);
    //   }
    // }
    // &::-webkit-scrollbar-track {
    //   background: transparent;
    // }
    // &::-webkit-scrollbar-thumb {
    //   background: rgba(var(--gray-rgba), 0.1);
    //   border-radius: 10px;
    //   visibility: hidden;
    //   transition: visibility 0.3s;
    //   @include desktopStyle {
    //     border-radius: get-vw(10px);
    //   }
    // }
    @include desktopStyle {
      max-height: get-vw(897px);
    }
    @media only screen and (max-width: $bp-tablet) {
      max-height: 657px;
    }
  }

  &_block {
    padding-top: 32px;
    margin-top: 32px;
    border-top: 1px solid var(--gray-second);
    @include desktopStyle {
      padding-top: get-vw(32px);
      margin-top: get-vw(32px);
    }
    @media only screen and (max-width: $bp-tablet) {
      padding-top: 24px;
      margin-top: 24px;
    }
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      padding-bottom: 30px;
      @include desktopStyle {
        padding-bottom: get-vw(20px);
      }
    }
  }

  &_title {
    margin-bottom: 20px;
    color: var(--blue);
    @include desktopStyle {
      margin-bottom: get-vw(20px);
    }
    @media only screen and (max-width: $bp-tablet) {
      margin-bottom: 8px;
    }
  }

  &_text {
    color: var(--gray);
  }

  &_bottom {
    margin-top: 90px;
    position: relative;
    z-index: 2;
    gap: 24px;
    grid-gap: 24px;
    @include desktopStyle {
      gap: get-vw(24px);
      grid-gap: get-vw(24px);
      margin-top: get-vw(90px);
    }
    @media only screen and (max-width: $bp-tablet) {
      margin-top: 32px;
      justify-content: center;
    }
  }

  &_btn {
    background: transparent;
    border: none;
    padding: 0;
    padding: 0;
    width: 24px;
    height: 24px;
    cursor: pointer;
    outline: none;
    transform: rotate(90deg);
    transition: all 0.3s;
    color: var(--gray-dark);
    @include desktopStyle {
      width: get-vw(24px);
      height: get-vw(24px);
    }
    &:not([disabled]):hover {
      color: var(--blue);
    }
    &[disabled] {
      cursor: no-drop;
      opacity: 0.2;
    }
    svg {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
  }

  &.with-btns {
    &::after,
    &::before {
      filter: none;
      content: '';
      position: absolute;
      display: block;
      left: 0;
      width: 100%;
      height: 100px;
      z-index: 1;
      background: linear-gradient(180deg, var(--gray-bg) 0%, var(--gray-bg) 11.98%, rgba(var(--gray-bg-rgba), 0.94) 48.44%, rgba(var(--gray-bg-rgba), 0) 100%);
      pointer-events: none;
      transition: opacity .3s;
      @include desktopStyle {
        height: get-vw(100px);
      }
    }
    &::before {
      top: -47px;
      @include desktopStyle {
        top: get-vw(-47px);
      }
      @media only screen and (max-width: $bp-tablet) {
        content: none;
      }
    }
    &::after {
      bottom: 57px;
      transform: rotate(180deg);
      @include desktopStyle {
        bottom: get-vw(57px);
      }
      @media only screen and (max-width: $bp-tablet) {
        background: linear-gradient(180deg, var(--gray-bg) 0%, var(--gray-bg) 11.98%, rgba(var(--gray-bg-rgba), 0.94) 48.44%, rgba(var(--gray-bg-rgba), 0) 100%);
        height: 80px;
        bottom: 15px;
      }
    }

    &.is-disabled-up::before,
    &.is-disabled-down::after {
      opacity: 0;
    }
  }

  &.with-tabs {
    .slider_content {
      max-height: 450px;
      @include desktopStyle {
        max-height: get-vw(450px);
      }
      @media only screen and (max-width: $bp-tablet) {
        max-height: 657px;
      }
    }
  }
}
