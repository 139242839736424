@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
.team-modal {
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(#3A4252, 0.5);
  height: 100vh;
  bottom: 0;
  padding: 20px 0;
  backdrop-filter: blur(8px);
  overflow: auto;
  @include desktopStyle {
    padding: get-vw(20px) 0;
  }
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: var(--blue-light);
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(var(--blue-rgba), 0.8);
    border-radius: 4px;
  }
  @media only screen and (max-width: $bp-tablet) {
    height: 100dvh;
  }

  &_wrapper {
    background: var(--white);
    width: 100%;
    max-width: 1268px;
    padding: 56px;
    margin: auto;
    border-radius: 12px;
    gap: 24px 60px;
    grid-gap: 24px 60px;
    position: relative;
    @include desktopStyle {
      max-width: get-vw(1268px);
      padding: get-vw(56px);
      border-radius: get-vw(12px);
      gap: get-vw(24px) get-vw(60px);
      grid-gap: get-vw(24px) get-vw(60px);
    }
    @media only screen and (max-width: $bp-tablet) {
      padding: 32px;
    }
    @media only screen and (max-width: $bp-phone) {
      padding: 16px;
    }
  }

  &_btn-close {
    right: 24px;
    top: 24px;
    position: absolute;
    background: transparent;
    padding: 0;
    border: none;
    cursor: pointer;
    outline: none;
    width: 24px;
    height: 24px;
    @include desktopStyle {
      right: get-vw(24px);
      top: get-vw(24px);
      width: get-vw(24px);
      height: get-vw(24px);
    }
    &::after,
    &::before {
      content: '';
      position: absolute;
      width: 2px;
      height: 91%;
      background: var(--gray-light);
      border-radius: 4px;
      top: 3%;
      left: 50%;
      margin-left: -1px;
      @include desktopStyle {
        width: get-vw(2px);
        margin-left: get-vw(-1px);
      }
    }
    &::after {
      transform: rotate(-45deg);
    }
    &::before {
      transform: rotate(45deg);
    }
  }

  &_image {
    width: 310px;
    border-radius: 8px;
    overflow: hidden;
    background: var(--bg-blue-light-2);
    @include desktopStyle {
      width: get-vw(310px);
      border-radius: get-vw(8px);
    }
    @media only screen and (max-width: $bp-phone) {
      width: 165px;
    }
    & + .team-modal_content {
      width: calc(100% - 370px);
      @include desktopStyle {
        width: calc(100% - get-vw(370px));
      }
      @media only screen and (max-width: $bp-tablet) {
        width: 100%;
      }
    }
    img {
      display: block;
      width: 100%;
    }
  }

  &_position,
  &_description {
    color: var(--gray);
  }

  &_position {
    margin-top: 8px;
    @include desktopStyle {
      margin-top: get-vw(8px);
    }
  }

  &_logo {
    margin-top: 60px;
    margin-bottom: 32px;
    @include desktopStyle {
      margin-top: get-vw(60px);
      margin-bottom: get-vw(32px);
    }
    @media only screen and (max-width: $bp-tablet) {
      margin-top: 32px;
    }
    img {
      display: block;
    }
  }

  &_title {
    & + .team-modal_link {
      margin-top: 8px;
      @include desktopStyle {
        margin-top: get-vw(8px);
      }
    }
  }

  &_title,
  &_description,
  &_link {
    margin-top: 32px;
    @include desktopStyle {
      margin-top: get-vw(32px);
    }
    @media only screen and (max-width: $bp-tablet) {
      margin-top: 24px;
    }
  }

  &_link {
    display: inline-block;
    text-decoration: none;
    color: var(--blue);
    &:hover {
      text-decoration: underline;
    }
  }
}