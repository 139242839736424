@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
.vacancies {
  padding: 120px 0;
  @include desktopStyle {
    padding-top: get-vw(120px);
    padding-bottom: get-vw(120px);
  }
  @media only screen and (max-width: $bp-tablet) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  @media only screen and (max-width: $bp-phone) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &_left {
    width: 30%;
    @media only screen and (max-width: $bp-tablet) {
      width: 100%;
      flex-direction: column-reverse;
      align-items: center;
      margin-bottom: 32px;
    }
  }

  &_right {
    width: 64%;
    max-width: 1010px;
    position: relative;
    min-height: 300px;
    @include desktopStyle {
      max-width: get-vw(1010px);
      min-height: get-vw(300px);
    }
    @media only screen and (max-width: $bp-tablet) {
      width: 100%;
      max-width: none;
    }
  }

  &_loader {
    position: absolute;
    left: 0;
    top: 100px;
    width: 100%;
    height: calc(100% - 100px);
    background: rgb(var(--white-rgba), 0.6);
    :global(.loader) {
      border-left-color: var(--blue);
      border-right-color: var(--blue);
      border-top-color: var(--blue);
    }
  }

  &_title {
    width: 100%;
  }

  &_image {
    margin-top: 60px;
    @include desktopStyle {
      margin-top: get-vw(60px);
    }
    @media only screen and (max-width: $bp-tablet) {
      margin-top: 0;
      max-width: 66.4%;
      margin-bottom: 32px;
      img {
        margin: 0 auto;
      }
    }
    img {
      display: block;
    }
  }

  &_filter {
    gap: 24px;
    grid-gap: 24px;
    margin-bottom: 40px;
    @include desktopStyle {
      gap: get-vw(24px);
      grid-gap: get-vw(24px);
      margin-bottom: get-vw(40px);
    }
    @media only screen and (max-width: $bp-tablet) {
      gap: 16px;
      grid-gap: 16px;
    }
    :global(.form_item) {
      margin-bottom: 0;
      width: 100%;
      max-width: 300px;
      @include desktopStyle {
        max-width: get-vw(300px);
      }
      @media only screen and (max-width: $bp-phone) {
        max-width: none;
      }
    }
  }

  &_pagination {
    @media only screen and (min-width: $bp-tablet + 1) {
      margin-top: 40px;
      @include desktopStyle {
        margin-top: get-vw(40px);
      }
      :global(.pagination_actions) {
        justify-content: flex-start;
      }
    }
  }

  &_footer {
    padding: 4rem;
    border-radius: 1.2rem;
    background-color: var(--gray-bg);
    margin-top: 6rem;
    @media only screen and (max-width: $bp-tablet) {
      padding: 1.6rem;
      border-radius: 0.8rem;
      margin-top: 3.2rem;
      justify-content: center;
    }

    &-img {
      margin-right: 4rem;
      @media only screen and (max-width: $bp-tablet) {
        margin-right: 1.2rem;
      }

      img {
        display: block;
        width: 10rem;
        height: auto;
        @media only screen and (max-width: $bp-tablet) {
          width: 4.6rem;
        }
      }
    }
    &-descr {
      font-family: $font-main-SB;
      font-size: 2.6rem;
      font-weight: 600;
      line-height: 1.5;
      @media only screen and (max-width: $bp-tablet) {
        font-size: 2rem;
        line-height: 1.3;
      }

      a {
        color: var(--blue);
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  :global {
    .vacancies_info {
      color: var(--gray);
      font-size: 1.6rem;
      gap: 24px;
      grid-gap: 24px;
      margin-top: 12px;
      @include desktopStyle {
        gap: get-vw(24px);
        grid-gap: get-vw(24px);
        margin-top: get-vw(12px);
      }
      @media only screen and (max-width: $bp-phone) {
        font-size: 1.4rem;
        gap: 12px 16px;
        grid-gap: 12px 16px;
        margin-top: 20px;
      }
    }

    .vacancies_info-item {
      gap: 8px;
      grid-gap: 8px;
      @include desktopStyle {
        gap: get-vw(8px);
        grid-gap: get-vw(8px);
      }
      @media only screen and (max-width: $bp-phone) {
        gap: 4px;
        grid-gap: 4px;
      }
    }

    .vacancies_info-icon {
      width: 16px;
      height: 16px;
      @include desktopStyle {
        width: get-vw(16px);
        height: get-vw(16px);
      }
    }
  }
}
